.about {
  background: url(../i/bg.jpg) no-repeat 50% 50%;
  background-size: cover;
  background-attachment: fixed;
  color: $white;

  &__content {
    font-size: 24px;
    line-height: 36px;
    font-weight: 300;
    padding-bottom: 7px;

    b {
      font-weight: bold; }

    a {
      color: $white; }

    ul {
      margin-bottom: 46px; }

    li {
      list-style: none;
      padding-left: 25px;
      position: relative;
      margin-bottom: 12px;

      &::before {
        content: '';
        display: block;
        position: absolute;
        border-radius: 50%;
        width: 8px;
        height: 8px;
        left: 0;
        top: 12px;
        background-color: $white; }

      &:last-child {
        margin-bottom: 0; } }

    p {
      margin-bottom: 46px;

      &:last-child {
        margin-bottom: 0; } } } }


@media ($tablet) {

  .about {

    &__content {
      font-size: 20px;
      line-height: 30px;

      ul,
      p {
        margin-bottom: 35px; } } } }


@media ($tablet) {

  .about {

    &__content {
      font-size: 16px;
      line-height: 24px;

      li {
        margin-bottom: 15px; }

      ul,
      p {
        margin-bottom: 35px; } } } }


@supports (-ms-ime-align:auto) {

  .about {
    background-attachment: local !important; } }
