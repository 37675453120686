.contacts {

  &__form {
    border-radius: 18px;
    box-shadow: 0 8px 25px rgba(0,0,0,.1);
    display: flex;
    padding: 50px;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    min-height: 540px;
    position: relative;

    &.preloader {

      &::before {
        position: absolute;
        content: '';
        display: block;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 50;
        background: rgba(255,255,255,.6); } } }

  &__wrap {
    max-width: 745px;
    width: 100%;

    .contacts__captcha {
      display: none;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center; } }

  &__success {
    font-size: 24px;
    text-align: center;
    font-weight: 300;

    b {
      display: block;
      font-size: 37px;
      margin-bottom: 17px;
      font-weight: 300; } } }

@media ($tablet) {

  .contacts {

    &__form {
      padding: 35px; }

    &__success {
      font-size: 20px;

      b {
        font-size: 32px; } } } }


@media ($mobile) {

  .contacts {

    &__form {
      box-shadow: 0 0 #000;
      padding: 0;
      min-height: 0; }

    &__success {
      font-size: 16px;

      b {
        font-size: 26px; } } } }
