@mixin clearfix() {
  &:after {
    content: '';
    display: block;
    clear: both;
    visibility: hidden;
    height: 0;
    line-height: 0;
    font-size: 0; } }

@mixin placeholder() {
  &::-webkit-input-placeholder {
    @content; }
  &:-moz-placeholder {
    @content; }
  &::-moz-placeholder {
    @content; }
  &:-ms-input-placeholder {
    @content; }
  &:placeholder {
    @content; } }

.hide {
  visibility: hidden;
  opacity: 0;
  transition: opacity $fast, visibility 0s $fast; }

.show {
  visibility: visible;
  opacity: 1;
  transition-delay: 0s; }

@mixin hide {
  visibility: hidden;
  opacity: 0;
  transition: opacity $fast, visibility 0s $fast; }

@mixin show {
  visibility: visible;
  opacity: 1;
  transition-delay: 0s; }

%hide {
  @include hide; }

%show {
  @include show; }
