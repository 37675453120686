.nav {
  background-color: #fee101;

  .wrap {
    padding-top: 40px;
    padding-bottom: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center; }

  &_tiny {

    .wrap {
      padding-top: 13px;
      padding-bottom: 14px; }

    .nav__list a {
      font-size: 13px; } }

  &__list {
    display: flex;

    a {
      font-size: 16px;
      font-weight: 300;
      text-transform: uppercase;
      text-decoration: none;
      margin-left: 30px;
      position: relative;

      &::before {
        content: '';
        background-color: $black;
        height: 1px;
        left: 0;
        bottom: -5px;
        width: 0;
        position: absolute;
        display: block;
        transition: width $fast; }

      &:hover::before {
        width: 100%; } } }

  &__close {
    display: none; } }


@media ($tablet) {

  .nav {

    .wrap {
      padding-top: 30px;
      padding-bottom: 30px; }

    &__container {

      .nav__block {
        display: none;
        // visibility: hidden
        // opacity: 0
 }        // transition: opacity $fast, visibility 0s $fast

      &.opened .nav__block {
        display: block;
        // visibility: visible
        // opacity: 1
 } }        // transition-delay: 0s

    &__close {
      position: absolute;
      right: 0;
      top: 0;
      width: 18px;
      height: 18px;
      padding: 20px;
      cursor: pointer;
      transform: rotate(45deg);
      display: block;

      &::before,
      &::after {
        content: '';
        display: block;
        position: absolute;
        background-color: #d8d8d8;
        transition: background-color $fast; }

      &::before {
        width: 18px;
        height: 2px;
        top: 28px;
        left: 20px; }

      &::after {
        height: 18px;
        width: 2px;
        top: 20px;
        left: 28px; }

      &:hover {

        &::before,
        &::after {
          background-color: #999; } } }

    &__list {
      position: fixed;
      right: 0;
      top: 0;
      bottom: 0;
      background-color: $white;
      flex-direction: column;
      max-width: 400px;
      width: 100%;
      z-index: 600;
      padding: 50px 0;
      box-sizing: border-box;

      a {
        display: block;
        padding: 12px 20px;
        font-size: 18px !important;

        &::before {
          display: none; } } }

    &__overlay {
      position: fixed;
      right: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      z-index: 550;
      background: rgba(0,0,0,.4); }

    &__i {
      position: absolute;
      right: 10px;
      top: 14px;
      padding: 20px;
      height: 12px;
      width: 20px;
      cursor: pointer;

      &::before,
      &::after {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        width: 20px;
        box-sizing: border-box;
        left: 20px; }

      &::before {
        border: solid $black;
        border-width: 2px 0;
        height: 12px;
        top: 20px; }

      &::after {
        height: 2px;
        background-color: $black;
        top: 25px; } } } }

@media ($mobile) {

  .nav {

    &__i {
      right: 0; } } }
