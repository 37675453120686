.project {

  & + .stats .wrap {
    padding-top: 0; }

  &__descr {
    font-size: 24px;
    line-height: 36px;
    font-weight: 300;
    margin-bottom: 60px; }

  &__container {
    margin-bottom: 20px;
    margin-left: -70px;

    .swiper-wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between; }

    .swiper-pagination {
      display: none; } }

  &__block {
    display: block;
    margin-bottom: 35px;
    text-align: center;
    max-width: 220px;
    text-decoration: none;
    margin-left: 70px;

    &:hover {

      .project__pic {
        filter: saturate(2); } } }

  &__pic {
    display: block;
    border-radius: 42px;
    margin-bottom: 11px;
    transition: all $fast; }

  &__name {
    font-size: 18px;
    line-height: 24px;
    font-weight: bold;
    margin-bottom: 5px; }

  &__about {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 9px;
    font-weight: 300; }

  &__lang {
    font-size: 13px;
    color: #1e7fd3;
    font-weight: 300; }

  &__list {

    li {
      padding-left: 28px;
      position: relative;
      margin-bottom: 10px;
      font-size: 24px;
      line-height: 36px;
      list-style: none;
      font-weight: 300;

      &::before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 12px;
        border-radius: 50%;
        width: 8px;
        height: 8px;
        background-color: $black; }

      &:last-child {
        margin-bottom: 0; } } } }


@media ($tablet) {

  .project {

    &__descr {
      font-size: 20px;
      line-height: 30px;
      margin-bottom: 40px; }

    &__container {
      overflow: hidden;
      width: 100%;
      margin-left: 0;
      position: relative;

      &::before {
        content: '';
        display: block;
        position: absolute;
        right: 0;
        width: 20px;
        top: 0;
        bottom: 0;
        z-index: 50;
        background: linear-gradient(to left, white, rgba(255,255,255,0)); }

      .swiper-wrapper {
        flex-wrap: nowrap; }

      .swiper-pagination {
        display: block; }

      .swiper-pagination-bullet-active {
        background-color: #1e7fd3; } }

    &__block {
      margin-left: 40px;

      &:first-child {
        margin-left: 0; } }

    &__list {

      li {
        font-size: 20px;
        line-height: 30px; } } } }


@media ($mobile) {

  .project {

    &__descr {
      font-size: 18px;
      line-height: 24px; }

    &__block {
      margin-left: 20px;

      &:first-child {
        margin-left: 0; } }

    &__list {

      li {
        font-size: 18px;
        line-height: 24px; } } } }


@media (min-width: 960px) {

  .project {

    &__container {

      .swiper-wrapper {
        transform: translate(0, 0) !important; } } } }
