.button__container {
  display: flex;
  justify-content: center;
  padding-top: 20px; }

.button {
  position: relative;
  overflow: hidden;
  color: #2a2742;
  box-sizing: border-box;
  text-align: center;
  padding: 15px 50px;
  background: #1e7fd3;
  cursor: pointer;
  text-decoration: none;
  border: 0;
  font-size: 18px;
  font-weight: 300;
  line-height: 1;
  border-radius: 7px;
  transition: all $fast;
  display: block;
  box-shadow: 0 0 0 #000;
  color: $white;

  &:hover {
    background-color: #0a64b1; }

  &:active {
    box-shadow: inset 0 3px 5px rgba(0,0,0,.3); }

  &:disabled {
    opacity: .5; }

  &_invert {
    color: $white;

    &:hover {
      color: $black;
      background-color: $blue; } } }

.button {
  input {
    position: absolute;
    display: block;
    left: 0;
    top: 0;
    width: 800px;
    height: 300px;
    cursor: pointer;
    border: 0;
    opacity: 0; } }

.button__browse {
  input[type="file"] {
    font: 199px Verdana, sans-serif;
    position: absolute;
    top: -30px;
    right: -20px;
    cursor: pointer;
    opacity: 0; } }
