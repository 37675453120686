.footer {
  background-color: #f5f5f5;

  .wrap {
    padding-top: 0;
    padding-bottom: 0; }

  &__nav {
    display: flex;
    justify-content: center;
    font-size: 13px;
    text-transform: uppercase;

    a {
      color: #291f1f;
      text-decoration: none;
      padding: 12px 20px;
      transition: background-color $fast;

      &:hover {
        background-color: #ccc; } } } }



@media ($tablet) {

  .footer {

    &__nav {
      font-size: 12px;

      a {
        padding: 12px 15px; } } } }


@media ($mobile) {

  .footer {

    &__nav {
      flex-direction: column;
      align-items: center;
      padding: 15px 0;

      a {
        padding: 12px 15px; } } } }
