.content {
  font-size: 18px;
  line-height: 24px;
  font-weight: 300;

  section {
    padding-bottom: 25px; }

  h1 {
    text-align: center;
    font-size: 37px;
    line-height: 1;
    font-weight: bold;
    margin-bottom: 45px; }

  p {
    margin-bottom: 25px; }

  ul {
    margin-bottom: 17px; }

  li {
    margin-bottom: 8px;
    position: relative;
    padding-left: 31px;
    list-style: none;

    &::before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 9px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: $black; }

    ul {
      padding-top: 15px; }

    li {

      &::before {
        background-color: #ccc; } } }

  h3 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 25px; }

  h4 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 25px; }

  a {
    color: #1e7fd3; } }


@media ($tablet) {

  .content {
    font-size: 16px;
    line-height: 22px;

    h1 {
      font-size: 34px;
      margin-bottom: 35px; }

    h3 {
      font-size: 22px; }

    h4 {
      font-size: 16px; } } }
