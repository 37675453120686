.stats {
  margin-bottom: -8px;

  &__container {
    display: flex;
    flex-wrap: wrap;
    margin-top: -55px; }

  &__block {
    width: 50%;
    padding-top: 55px;
    font-size: 18px;
    line-height: 24px;
    font-weight: 300;
    box-sizing: border-box;
    display: flex;
    align-items: center;

    b {
      line-height: 1;
      font-size: 50px;
      font-weight: 300;
      width: 175px;
      box-sizing: border-box;
      padding-right: 20px;
      display: block; }

    span {
      display: block;
      width: calc(100% - 175px); }

    &:nth-child(2n) {
      padding-left: 40px; }

    &:nth-child(2n+1) {
      padding-right: 40px; } } }


@media ($tablet) {

  .stats {

    &__container {
      margin-top: -35px; }

    &__block {
      font-size: 16px;
      line-height: 22px;
      padding-top: 40px;

      b {
        font-size: 40px;
        width: 150px; }

      span {
        width: calc(100% - 150px); } } } }


@media ($mobile) {

  .stats {

    &__container {
      margin-top: -35px; }

    &__block {
      font-size: 16px;
      line-height: 22px;
      width: 100%;
      padding-left: 0 !important;
      padding-right: 0 !important;
      flex-direction: column;
      text-align: center;

      b {
        font-size: 32px;
        width: auto;
        margin-bottom: 15px; }

      span {
        width: auto; } } } }
