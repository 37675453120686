// Animation duration
$slow: 1s;
$default: .5s;
$fast: .3s;

// MediaQuery
$mobile: 'max-width: 640px';
$tablet: 'max-width: 960px';
// $desktop: 'min-width: 1070px'

// Colors
$black: #000;
$white: #fff;
$purp: #7106ea;
$grey: #9686a1;
$pink: #f20088;

$dark: #282b3a;
$light: #f0f1f5;
$green: #a7f20d;
$blue: #00c6ff;

$grey_dark: #54668a;
$grey_light: #dcdde2;
$blue_light: #4cdbe8;
$red: red;

// Fonts
$primary: Helvetica, Arial, sans-serif;

// Margin & padding
$max: 1120px;
