body, html, p, ul, ol, li, dl, dd, dt, h1, h2, h3, h4, h5, h6, form, fieldset, td, th, caption, address, textarea, legend, blockquote {
  margin: 0;
  padding: 0; }

img,
fieldset {
  border: 0; }

table {
  border-collapse: collapse; }

th,
td {
  vertical-align: top; }

input::-ms-clear {
  display: none; }

// Remove mobile safari controls
video::-webkit-media-controls {
  display:none !important {} }

*::-webkit-media-controls-panel {
  display: none!important;
  -webkit-appearance: none; }

*::-webkit-media-controls-play-button {
  display: none!important;
  -webkit-appearance: none; }

*::-webkit-media-controls-start-playback-button {
  display: none!important;
  -webkit-appearance: none; }

// Remove chrome autofill yellow bg
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-transition-delay: 9999s;
  -webkit-transition: color 9999s ease-out, background-color 9999s ease-out; }
