.dialog {
  background-color: $white;
  margin: auto;
  border-radius: 20px;
  padding: 60px 70px 70px;
  box-sizing: border-box;
  max-width: 1025px;
  width: 90%;
  text-align: center;
  position: relative;
  display: none;

  &__overlay {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    display: flex;
    background-color: rgba(53,55,68,.8);
    overflow: auto;
    @extend .hide;

    &.dialog_opened {
      @extend .show; } }

  &__close {
    position: absolute;
    right: 0;
    top: 0;
    width: 18px;
    height: 18px;
    padding: 20px;
    cursor: pointer;
    transform: rotate(45deg);

    &::before,
    &::after {
      content: '';
      display: block;
      position: absolute;
      background-color: #d8d8d8;
      transition: background-color $fast; }

    &::before {
      width: 18px;
      height: 2px;
      top: 28px;
      left: 20px; }

    &::after {
      height: 18px;
      width: 2px;
      top: 20px;
      left: 28px; }

    &:hover {

      &::before,
      &::after {
        background-color: #999; } } }

  &__title {
    font-size: 37px;
    font-weight: bold;
    margin-bottom: 24px; }

  &__descr {
    font-size: 18px;
    line-height: 24px;
    font-weight: 300;
    margin-bottom: 64px; }

  &__pic {
    display: block;
    margin: 0 auto 50px;
    border-radius: 16px;
    overflow: hidden;
    width: 165px;
    height: 165px;

    img {
      display: block;
      width: 165px;
      height: 165px; } }

  &__lang {
    display: flex;
    flex-wrap: wrap;
    list-style: none;

    li {
      width: 220px;
      display: flex;
      align-items: center;
      margin-top: 20px;
      text-align: left;
      font-size: 16px;
      line-height: 20px;
      font-weight: 300;
      padding-right: 20px;
      box-sizing: border-box;
      text-decoration: none; }

    &-pic {
      margin-right: 15px;
      display: block; } } }


@media ($tablet) {

  .dialog {
    padding: 30px 40px;
    width: 540px;

    &__title {
      font-size: 32px; }

    &__descr {
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 40px; }

    &__pic {
      margin-bottom: 40px; }

    &__lang {
      font-size: 14px; } } }
