.page {
  min-width: 320px;
  position: relative;
  min-height: 100%;
  overflow: hidden; }

.wrap {
  padding: 128px 40px;
  margin: 0 auto;
  max-width: $max;
  box-sizing: border-box; }

.title {
  font-size: 37px;
  font-weight: bold;
  line-height: 1;
  text-align: center;
  margin-bottom: 50px;

  &__sec {
    font-size: 24px;
    line-height: 36px;
    font-weight: 300;
    margin-bottom: 50px;
    text-align: center; } }


@media ($tablet) {

  .wrap {
    padding: 85px 30px; }

  .title {
    font-size: 32px;
    margin-bottom: 35px; } }


@media ($mobile) {

  .wrap {
    padding: 50px 20px; }

  .title {
    font-size: 32px;
    margin-bottom: 35px;

    &__sec {
      font-size: 20px;
      line-height: 30px; } } }
