// Uploader
.uploader {
  position: relative;
  display: inline-block;
  padding: 10px 30px;
  vertical-align: middle;
  background: blue;
  color: #fff;
  font: 12px Arial, sans-serif;
  overflow: hidden;

  &:hover {
    background: orange;
    color: #000; }

  input {
    font: 199px Verdana, sans-serif;
    position: absolute;
    top: -30px;
    right: -20px;
    cursor: pointer;
    opacity: 0; } }


// Checkbox

.checkbox__block {
  margin-bottom: 25px;

  &_small {
    margin-bottom: 5px; } }

.checkbox__label {
  position: relative;
  padding-left: 25px;
  display: inline-block;

  &:hover .checkbox {
    border-color: $black; }

  input[type="checkbox"],
  input[type="radio"] {
    visibility: hidden;
    height: 0;
    width: 0;
    border: 0;
    overflow: hidden;
    position: absolute;
    left: 0;
    top: 0; } }

.checkbox {
  width: 15px;
  height: 15px;
  border: 1px solid $black;
  box-sizing: border-box;
  top: 0;
  left: 0;
  position: absolute;
  overflow: hidden;
  vertical-align: top;
  transition: border-color $fast; }

input[type=checkbox]:checked + .checkbox + .checkbox-i {
  opacity: 1; }

input[type=checkbox]:disabled + .checkbox {
  background-color: $black !important;
  border-color: $black !important;

  & + .checkbox-i {
    fill: $black !important; } }

.checkbox-i {
  fill: $black;
  width: 14px;
  height: 14px;
  display: block;
  position: absolute;
  left: 1px;
  top: 0;
  opacity: 0;
  transition: opacity $fast; }


// Radio

.radio__label {
  position: relative;
  padding-left: 33px;
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
  color: #939598;
  margin-bottom: 25px;

  // &:hover .radio
  //   border-color: #000

  input {
    display: none; } }

.radio {
  width: 22px;
  height: 22px;
  border: 2px solid #9c9da0;
  box-sizing: border-box;
  background: $white;
  top: -3px;
  position: absolute;
  left: 0;
  overflow: hidden;
  border-radius: 50%;
  transition: border-color $fast; }

input[type=radio]:checked + .radio::before {
  content: '';
  display: block;
  position: absolute;
  width: 14px;
  height: 14px;
  left: 2px;
  top: 2px;
  background: #4ae4e4;
  border-radius: 50%;
  transition: background-color $fast; }

input[type=radio]:disabled + .radio {
  border-color: $black !important;
  background-color: $black !important;

  &::before {
    background-color: $black !important; } }

// Fields

.field__block {
  position: relative;
  margin-bottom: 20px;
  width: 100%;
  text-align: center; }

.field__label {
  font-size: 14px;
  font-weight: bold;
  color: #939598;
  margin-bottom: 6px;
  display: inline-block; }

.field {
  border: 1px solid #dddddd;
  width: 100%;
  box-sizing: border-box;
  display: block;
  font-size: 18px;
  color: #000;
  background: $white;
  font-weight: 300;
  padding: 15px 18px;
  border-radius: 8px;
  -webkit-appearance: none;
  transition: all $fast;

  @include placeholder() {
    color: #c7c7c7;
    opacity: 1; }

  &:disabled {
    color: $black;
    opacity: 1; }

  &.error,
  &.error:hover,
  &.error:focus {
    border-color: $red; }

  &:hover,
  &:focus {
    border-color: #1e7fd3; }

  &__group {
    display: flex;
    justify-content: space-between;

    .field__block {
      width: calc(50% - 10px); } } }

textarea.field {
  height: 100px;
  resize: vertical;
  min-height: 100px; }


@media ($mobile) {

  .field {

    &__group {
      flex-direction: column;

      .field__block {
        width: 100%; } } } }


// Error

.field_error,
.field_error:hover,
.field_error:focus {
  border-color: $red;
  color: $red; }

label.error,
.field_error-message {
  color: $red;
  font-size: 13px;
  line-height: 1.6;
  display: block;
  padding: 6px 0 0;
  position: relative;
  left: 0;
  text-align: left; }


// select2
.select2 {
  width: 100% !important;
  text-align: left; }

.select2-container--default .select2-selection--single {
  border-color: #dddddd;
  box-sizing: border-box;
  display: block;
  font-size: 18px;
  color: #000;
  padding: 10px 18px;
  height: 50px;
  line-height: 1;
  border-radius: 8px; }

.select2-container .select2-selection--single .select2-selection__rendered {
  padding-left: 0; }

.select2-container--default .select2-selection--single .select2-selection__arrow {
  top: 11px;
  right: 15px; }

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-top-color: #dddddd;
  border-left-width: 6px;
  border-right-width: 6px;
  border-top-width: 8px; }

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-width: 0 6px 8px 6px;
  border-color: transparent transparent #dddddd transparent; }

.select2-results__option {
  padding: 10px 18px;
  font-size: 18px; }

.select2-container--default .select2-selection--single .select2-selection__placeholder {
  color: #c7c7c7;
  font-weight: 300; }
