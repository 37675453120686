.header {
  background-color: #fee101;
  text-align: center;
  min-height: 700px;
  display: flex;
  align-items: center;
  box-sizing: border-box;

  &__title {
    font-size: 60px;
    line-height: 1;
    font-weight: bold;
    margin-bottom: 17px; }

  &__descr {
    font-size: 36px;
    font-weight: 300;
    margin-bottom: 50px; }

  &__info {
    font-size: 24px;
    line-height: 1.3;
    font-weight: 300;

    p {
      margin-bottom: 21px;

      &:last-child {
        margin-bottom: 0; } } } }


@media ($tablet) {

  .header {
    min-height: 500px;

    &__title {
      font-size: 50px;
      margin-bottom: 15px; }

    &__descr {
      font-size: 28px;
      margin-bottom: 35px; }

    &__info {
      font-size: 20px; } } }


@media ($mobile) {

  .header {
    min-height: 0;

    &__title {
      font-size: 35px;
      margin-bottom: 20px; }

    &__descr {
      font-size: 24px; }

    &__info {
      font-size: 16px; } } }
