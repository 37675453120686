html,
body {
  height: 100%; }

html {
  font-size: 62.5%; }

body {
  color: $black;
  background: $white;
  font-family: $primary; }

a {
  color: $black;

  &:hover {
    text-decoration: none; } }

button,
input,
textarea,
select {
  outline: none;
  font-family: $primary; }

.hidden {
  opacity: 0;
  visibility: hidden;
  transition: opacity .5s, visibility 0s .5s; }

.opened > .hidden {
  opacity: 1;
  visibility: visible;
  transition-delay: 0s; }

.center {
  text-align: center; }

.page {
  min-width: 320px; }

.svg {
  position: absolute;
  left: -9999px;
  top: 0; }

// .check-device
//   z-index: 1
//
// @media ($tablet)
//
//   .check-device
//     z-index: 2
//
// @media ($desktop)
//
//   .check-device
//     z-index: 3
